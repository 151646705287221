const CONFIG = {
    SERVER_URL: 'https://space-demo-server.gdevents.pl',
    GAME_SERVER_URL: 'wss://space-demo-server.gdevents.pl',

    // SERVER_URL: '//localhost:8081',
    // GAME_SERVER_URL: 'ws://localhost:8081',

    // SERVER_URL: '//space-server.borek224.usermd.net',
    // GAME_SERVER_URL: 'wss://space-server.borek224.usermd.net'
}

export default CONFIG;
